<template>
  <div>
    <Loading v-if="loading" />
    <div class="login-bg">
      <div class="login-card">
        <img
          src="@/assets/logo_dourado.png"
          alt="Fabio Merli"
          class="mt-6 mb-8"
        />
        <div class="white--text mb-8 welcome">Welcome to Fabio Merli Merbership</div>
        <v-form v-model="valido" ref="formulario" class="login-form">
          <v-text-field
            ref="refEmail"
            label="E-mail"
            append-icon="person"
            type="email"
            v-on:keyup.enter="entrar"
            v-model="usuario"
            :rules="regrasUsuario"
            dense
            color="white"
            solo
          />

          <v-text-field
            id="password"
            label="Password"
            :type="showSenha ? 'text' : 'password'"
            v-on:keyup.enter="entrar"
            v-model="senha"
            :rules="regrasSenha"
            :append-icon="showSenha ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showSenha = !showSenha"
            color="white"
            solo
            class="pb-0"
            dense
          />
        </v-form>
        <div class="incorreto">
          <div class="text-center">
            <v-alert
              :value="alert"
              color="red"
              transition="scale-transition"
              text
            >
              <v-icon color="red" left>mdi-alert</v-icon>
              {{ mensagem }}
            </v-alert>
          </div>
        </div>
        <div class="login-action">
          <v-btn
            width="100%"
            color="button"
            v-on:click="entrar"
            class="mb-4 mt-2"
            >Access</v-btn
          >
        </div>
        <div class="esqueceu-a-senha">
          <span class="white--text"
            >Forgot password?
            <a class="white--text" @click="modalReset = true"
              >Click here to reset</a
            ></span
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{
            routeId ? "Reset Password" : "First Access"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valido" ref="formularioAlteracao" class="pt-4">
            <v-row class="pt-2 pb-2">
              <v-col cols="12" class="pa-0 pa-sm-1">
                <v-text-field
                  outlined
                  dense
                  id="password"
                  label="New password"
                  prepend-icon="lock"
                  :type="showNovaSenha ? 'text' : 'password'"
                  v-on:keyup.enter="alterarSenha"
                  v-model="novaSenha"
                  :rules="[(v) => !!v || 'New password is required field']"
                  :append-icon="showNovaSenha ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNovaSenha = !showNovaSenha"
                />
              </v-col>
              <v-col cols="12" class="pa-0 pa-sm-1">
                <v-text-field
                  outlined
                  dense
                  id="password"
                  label="Confirm new password"
                  prepend-icon="lock"
                  v-on:keyup.enter="alterarSenha"
                  v-model="novaSenhaConfirmacao"
                  :rules="[
                    (v) => !!v || 'Confirm new password is required field',
                  ]"
                  :type="showConfirmarSenha ? 'text' : 'password'"
                  :append-icon="showConfirmarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmarSenha = !showConfirmarSenha"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div class="pr-8 pl-8 text-center">
          <v-alert
            :value="alertConfirmacao"
            color="red"
            transition="scale-transition"
            text
          >
            <v-icon color="red" left>mdi-alert</v-icon>
            {{ mensagemAlteracao }}
          </v-alert>
        </div>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            @click="closeModal"
            text
            right
            class="text-caption"
          >
            <v-icon class="text-caption">mdi-close</v-icon> Cancel
          </v-btn>
          <v-btn color="blue" @click="alterarSenha" text class="text-caption">
            <v-icon class="text-caption">mdi-content-save-outline</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalIndicacao"
      max-width="600px"
      persistent
      :fullscreen="windowWidth <= 600"
      v-if="indicacao"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Registration</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formIndicacao" class="pt-4">
            <v-row class="pt-2 pb-2">
              <v-col cols="12" xs="12" class="pa-0">
                <Thumb
                  :item="indicacao"
                  @selecionarImagem="selecionarImagem($event)"
                />
              </v-col>
              <v-col cols="12" xs="12" class="pa-0">
                <v-text-field
                  ref="refNome"
                  label="Full Name"
                  dense
                  v-model="indicacao.nome"
                  clearable
                  outlined
                  :rules="[(v) => !!v || 'Full name is a required field']"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  ref="refTelefone"
                  label="Phone number"
                  dense
                  v-model="indicacao.telefone"
                  clearable
                  outlined
                  v-mask="['+## #####-######']"
                  :rules="[(v) => !!v || 'Phone number is a required field']"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  ref="refEmailIndicacao"
                  label="E-mail"
                  dense
                  v-model="indicacao.email"
                  clearable
                  outlined
                  :rules="[(v) => !!v || 'E-mail is a required field']"
                  :error-messages="indicacao.emailDuplicado"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  ref="refInstagram"
                  label="Instagram"
                  dense
                  v-model="indicacao.instagram"
                  clearable
                  outlined
                  :rules="[(v) => !!v || 'Instagram is a required field']"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="indicacao.termos"
                    :label="null"
                    color="primary"
                    hide-details
                    class="pa-0 mt-0"
                  ></v-checkbox>
                  <div>
                    <span
                      class="black--text"
                      @click="indicacao.termos = !indicacao.termos"
                      >I have read and accepted the terms and conditions.
                    </span>
                    <a class="blue--text ml-1" @click="abrirPoliticas"
                      >Terms and conditions</a
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            @click="confirmarIndicacao"
            text
            class="text-caption"
            :disabled="!indicacao.termos"
          >
            <v-icon class="text-caption">mdi-content-save-outline</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalPoliticas" fullscreen v-if="politicas">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Terms and conditions</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeModalPoliticas">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-0 pl-0 pr-0 pb-4">
          <vue-editor
            v-model="politicas"
            id="login-politica"
            disabled
            :editorOptions="editorOptions"
          />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            @click="closeModalPoliticas"
            text
            class="text-caption"
          >
            <v-icon class="text-caption">mdi-check</v-icon>
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalReset" max-width="500px" v-if="modalReset">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Reset your password</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeModalReset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4">
          <p class="title">
            Lost your password? Please enter your e-mail address. You will
            receive a link to create a new password via e-mail.
          </p>
          <v-form ref="formularioReset" class="pt-4">
            <v-row>
              <v-col cols="12" class="pl-3 pr-3 pt-0 pb-0">
                <v-text-field
                  ref="refEmailReset"
                  label="E-mail"
                  dense
                  v-model="emailReset"
                  clearable
                  outlined
                  :rules="[(v) => !!v || 'E-mail is a required field']"
                  :error-messages="emailResetErro"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="blue" @click="resetarSenha" text class="text-caption">
            <v-icon class="text-caption">mdi-lock-open-outline</v-icon>
            Reset password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MensagemErro
      :mensagemAlerta="mensagemAlerta"
      :alertaErro="alertaErro"
      @ocultarErro="alertaErro = false"
      :login="false"
    />
    <ModalSucesso
      :mensagem="mensagemAlerta"
      :alertaSucesso="alertaSucesso"
      :titulo="mensagemTitulo"
      @ocultarSucesso="ocultarSucesso"
    />
  </div>
</template>

<script>
import MensagemErro from "../../components/mensagem-erro.vue";
import ModalSucesso from "../../components/modal-sucesso.vue";
import Loading from "../../components/loading.vue";
import UsuarioService from "../../service/usuario-service.js";
import ClienteService from "../../service/cliente-service.js";
import Thumb from "../../components/thumb.vue";
import { mask } from "vue-the-mask";
import PoliticaPrivacidadeService from "../../service/politica-privacidade-service.js";
import { VueEditor } from "vue2-editor";
const usuarioService = new UsuarioService();
const politicaPrivacidadeService = new PoliticaPrivacidadeService();
const clienteService = new ClienteService();

export default {
  directives: {
    mask,
  },
  components: {
    Loading,
    MensagemErro,
    ModalSucesso,
    Thumb,
    VueEditor,
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      valido: true,
      validarLogin: false,
      alert: null,
      showSenha: false,
      usuario: null,
      senha: null,
      alertConfirmacao: false,
      routeId: null,
      routeToken: null,
      modalIndicacao: false,
      windowWidth: window.innerWidth,
      indicacao: null,
      politicas: null,
      modalPoliticas: false,
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      modal: false,
      modalReset: false,
      emailReset: null,
      emailResetErro: null,
    };
  },
  methods: {
    closeModalReset() {
      this.modalReset = false;
    },
    async resetarSenha() {
      if (this.$refs.formularioReset.validate()) {
        this.loading = true;
        let result = await usuarioService.consultarUsuarioPorEmail(
          this.emailReset,
          window.location.origin
        );
        if (result?.statusCode === 200) {
          this.mensagemAlerta = `E-mail successfully sent to ${this.emailReset}`;
          this.mensagemTitulo = "Reset your password";
          this.alertaSucesso = true;
          this.loading = false;
          this.modalReset = false;
        } else if (result?.statusCode === 404) {
          this.emailResetErro = result?.data.mensagem;
          setTimeout(() => {
            this.emailResetErro = null;
          }, 5000);
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      }
    },
    closeModal() {
      this.modal = false;
    },
    closeModalPoliticas() {
      this.modalPoliticas = false;
      this.modalIndicacao = true;
    },
    async abrirPoliticas() {
      if (!this.politicas) {
        this.loading = true;
        let result = await politicaPrivacidadeService.consultarPolitica(false);
        if (result?.statusCode === 200) {
          this.politicas = result?.data.texto;
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      }
      this.modalPoliticas = true;
      this.modalIndicacao = false;
    },
    selecionarImagem(callback) {
      if (callback.success) {
        this.indicacao.foto = callback.data.base64;
        this.indicacao.nomeArquivo = callback.data.name;
        this.indicacao.base64String = callback.data.base64;
      } else {
        this.mensagemAlerta = callback.data;
        this.alertaErro = true;
      }
    },
    async confirmarIndicacao() {
      if (this.$refs.formIndicacao.validate()) {
        this.loading = true;
        let result = await clienteService.clienteApp(this.indicacao);
        if (result?.statusCode === 200) {
          this.modalIndicacao = false;
          this.mensagemAlerta =
            "Thanks for registering! Your data will be analyzed and once approved, you will receive an email with instructions for accessing your customer area.";
          this.mensagemTitulo = "Registration complete";
          this.alertaSucesso = true;
          this.$refs.formIndicacao.resetValidation();
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      }
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
    },
    async entrar() {
      this.validarLogin = true;
      setTimeout(async () => {
        if (this.$refs.formulario.validate()) {
          this.loading = true;
          let result = await usuarioService.efetuarLoginApp(
            this.usuario,
            this.senha
          );
          if (result?.statusCode === 200) {
            if (result?.data.primeiroAcesso) {
              this.loading = false;
              this.modal = true;
              this.objLogin = result?.data;
            } else {
              this.loading = false;
              this.setLogin(result?.data);
            }
          } else {
            this.loading = false;
            if (result?.statusCode == 401) {
              this.mensagem = result?.data.mensagem;
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 3000);
            } else {
              this.mensagemAlerta = result?.data.mensagem;
              this.alertaErro = true;
            }
          }
        }
      }, 100);
    },
    async alterarSenha() {
      if (this.$refs.formularioAlteracao.validate()) {
        if (
          this.novaSenha === this.novaSenhaConfirmacao &&
          this.novaSenha !== this.objLogin.senha
        ) {
          this.loading = true;
          let result = await usuarioService.alterarSenhaUsuario(
            this.objLogin.id,
            this.novaSenha,
            false,
            false
          );
          if (result?.statusCode === 200) {
            this.loading = false;
            this.setLogin(this.objLogin);
          } else {
            this.loading = false;
            this.mensagemAlerta = result?.data.mensagem;
            this.alertaErro = true;
          }
        } else {
          this.alertConfirmacao = true;
          this.mensagemAlteracao = "The entered passwords do not match";
          setTimeout(() => {
            this.alertConfirmacao = false;
          }, 3000);
        }
      }
    },
    setLogin(objLogin) {
      const rotaNavegacao = localStorage.getItem("rotaNavegacao");

      localStorage.setItem("login", JSON.stringify(objLogin));
      if (rotaNavegacao) {
        localStorage.removeItem("rotaNavegacao");
        this.$router.push(rotaNavegacao).catch((failure) => {
          localStorage.setItem("erro", failure);
        });
      } else
        this.$router.push("/home#0").catch((failure) => {
          localStorage.setItem("erro", failure);
        });
    },
    async obterUsuarioReset() {
      this.loading = true;
      let result = await usuarioService.getById(this.routeId);
      if (result?.statusCode === 200) {
        this.loading = false;
        this.objLogin = result?.data;
        this.modal = true;
      } else {
        this.loading = false;
      }
    },
  },
  created: function () {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  mounted: async function () {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    const routeId = this.$route.query.id;
    if (routeId) {
      this.routeId = routeId;
      this.obterUsuarioReset();
    }
    const routeToken = this.$route.query.token;
    if (routeToken) {
      this.loading = true;
      let result = await clienteService.validaLink(routeToken);
      if (result?.statusCode === 200) {
        this.routeToken = routeToken;
        this.indicacao = {
          id: null,
          nome: null,
          email: null,
          emailDuplicado: null,
          telefone: null,
          instagram: null,
          foto: null,
          termos: false,
          clienteIndicacaoId: result?.data,
          ponto: 0,
          ativo: true,
          admin: false,
          primeiroAcesso: true,
          dataCadastro: null,
          clienteStatus: null,
          usuario: null,
          origemCadastro: null,
        };
        this.modalIndicacao = true;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    }
    if (!routeToken) {
      setTimeout(() => {
        this.$refs.refEmail.focus();
      }, 250);
    }
  },
  computed: {
    regrasUsuario() {
      const rules = [];
      if (this.validarLogin) {
        const rule = (v) => !!v || "E-mail is a required field";
        rules.push(rule);
      }
      return rules;
    },
    regrasSenha() {
      const rules = [];
      if (this.validarLogin) {
        const rule = (v) => !!v || "Password is a required field";
        rules.push(rule);
      }
      return rules;
    },
  },
};
</script>

<style scoped>
.login-bg {
  height: 100vh;
  width: 100vw;
  margin: 0;
  background-image: url("../../assets/login_bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 400px;
  height: 600px;
  padding: 12px 48px;
  background-color: black;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.75em;
}

.login-card img {
  height: 150px;
}

.login-form {
  width: 100%;
}

.login-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.incorreto {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.esqueceu-a-senha a {
  text-decoration: underline;
}

.welcome {
  font-size: 1.4rem;
}

@media (max-width: 600px) {
  .login-card {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    justify-content: center;
  }

  .esqueceu-a-senha {
    font-size: 0.9rem;
  }
}
</style>
<style>
#login-politica {
  height: calc(100vh - 130px);
}
</style>