import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class ClienteService extends FetchService {

    listarFiltros() {
        return {
            todos: null,
            nome: null,
            email: null,
            telefone: null,
            ponto: null,
            clienteStatus: null,
            ordenarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "Foto",
                valor: "foto",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "imagem",
                ordenado: null,
                ocultarResponsivo: true,
                desabilitarOrdenacao: true
            },
            {
                descricao: "Nome",
                valor: "nome",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Telefone",
                valor: "telefone",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "E-mail",
                valor: "email",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Pontos",
                valor: "ponto",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Status",
                valor: "clienteStatus",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarClientes(filtro) {
        const route = `${vue.$globals.endpoint}cliente/listaClientes`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarClientesExcel(filtro) {
        const route = `${vue.$globals.endpoint}cliente/exportarClientesExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}cliente`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}cliente`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async linkCompartilhamento(id, rota){
        const route = `${vue.$globals.endpoint}cliente/linkCompartilhamento?id=${id}&rota=${rota}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async validaLink(token){
        const route = `${vue.$globals.endpoint}cliente/validaLink?token=${token}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route,
            false
        );
    }

    async clienteApp(obj){
        const route = `${vue.$globals.endpoint}cliente/clienteApp`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao,
            obj,
            false, 
            route,
            false
        );
    }

    async selecionarCliente(id){
        const route = `${vue.$globals.endpoint}cliente/getById?id=${id}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao,
            null,
            false, 
            route
        );
    }

    async atualizarFoto(obj, id) {
        const route = `${vue.$globals.endpoint}cliente/updateFotoAsync/${id}`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async pontosCliente(id){
        const route = `${vue.$globals.endpoint}cliente/pontosCliente?clienteId=${id}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao,
            null,
            false, 
            route
        );
    }

    async totalIndicacoes(id){
        const route = `${vue.$globals.endpoint}cliente/totalIndicacoes?clienteId=${id}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao,
            null,
            false, 
            route
        );
    }
}

export default ClienteService;